import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import Fade from 'react-reveal/Fade'

import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'
import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi'

import '@brainhubeu/react-carousel/lib/style.css'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import PEOPLE from '../img/people.jpg'
import BRAND from '../img/brand.jpg'

import MEDIA_PARTNER_1 from '../img/media_partners/cox-media-group.png'
import MEDIA_PARTNER_2 from '../img/media_partners/debmar-mercury.png'
import MEDIA_PARTNER_3 from '../img/media_partners/family-feud.png'
import MEDIA_PARTNER_4 from '../img/media_partners/fox-soul.png'
import MEDIA_PARTNER_5 from '../img/media_partners/fremantle.png'
import MEDIA_PARTNER_6 from '../img/media_partners/hubbard-broadcasting.png'
// import MEDIA_PARTNER_7 from '../img/media_partners/Lx.png'
import MEDIA_PARTNER_8 from '../img/media_partners/meredith.png'
import MEDIA_PARTNER_9 from '../img/media_partners/morris-broadcasting.png'
import MEDIA_PARTNER_10 from '../img/media_partners/NBC.png'
import MEDIA_PARTNER_11 from '../img/media_partners/right-this-minute.png'
import MEDIA_PARTNER_12 from '../img/media_partners/sinclair-broadcasting-group.png'
import MEDIA_PARTNER_13 from '../img/media_partners/telemundo.png'
import MEDIA_PARTNER_14 from '../img/media_partners/the-weather-channel.png'
import MEDIA_PARTNER_15 from '../img/media_partners/abc.png'

import PAGE_CONTENTS from '../../content/pages/home.json'

const MEDIA_PARTNERS = [
  MEDIA_PARTNER_1,
  MEDIA_PARTNER_2,
  MEDIA_PARTNER_3,
  MEDIA_PARTNER_4,
  MEDIA_PARTNER_5,
  MEDIA_PARTNER_6,
  //   MEDIA_PARTNER_7,
  MEDIA_PARTNER_8,
  MEDIA_PARTNER_9,
  MEDIA_PARTNER_10,
  MEDIA_PARTNER_11,
  MEDIA_PARTNER_12,
  MEDIA_PARTNER_13,
  MEDIA_PARTNER_14,
  MEDIA_PARTNER_15,
]

const HERO_SLIDES = PAGE_CONTENTS.heroSlides || []

class Index extends React.Component {
  state = {
    slides: HERO_SLIDES,
    activeSlide: 0,
  }

  handleSlideChange = value => {
    this.slider.slickGoTo(value)
  }

  skipSlide = (e, { next }) => {
    e.preventDefault()

    const { activeSlide, slides } = this.state

    let newActiveSlide
    if (next) {
      if (activeSlide === slides.length - 1) {
        newActiveSlide = 0
      } else {
        newActiveSlide = activeSlide + 1
      }
    } else {
      if (activeSlide === 0) {
        newActiveSlide = slides.length - 1
      } else {
        newActiveSlide = activeSlide - 1
      }
    }

    this.setState({
      ...this.state,
      activeSlide: newActiveSlide,
    })
  }

  render() {
    const { data } = this.props
    let allShows = data.allMdx.edges

    const siteTitle = data.site.siteMetadata.title

    let testimonialsSlider
    const testimonialsSliderSettings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: true,
      initialSlide: 0,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      appendDots: dots => (
        <div>
          <div className="cp-slider-control">
            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                testimonialsSlider.slickPrev()
              }}
              className="cp-arrow-left"
            >
              <BsChevronLeft />
            </a>

            <ul style={{ margin: '0px' }}> {dots} </ul>

            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                testimonialsSlider.slickNext()
              }}
              className="cp-arrow-right"
            >
              <BsChevronRight />
            </a>
          </div>
        </div>
      ),
    }

    const settings = {
      arrows: false,
      dots: true,
      fade: true,
      infinite: true,
      initialSlide: 0,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      appendDots: dots => (
        <div>
          <div className="cp-slider-control">
            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                this.slider.slickPrev()
              }}
              className="cp-arrow-left"
            >
              <BsChevronLeft />
            </a>

            <ul style={{ margin: '0px' }}> {dots} </ul>

            <a
              href="#"
              onClick={e => {
                e.preventDefault()

                this.slider.slickNext()
              }}
              className="cp-arrow-right"
            >
              <BsChevronRight />
            </a>
          </div>
        </div>
      ),
    }

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension home">
            <div className="bg">
              <div className="container">
                <div className="cp-hero">
                  <Fade bottom duration={1000} delay={125} distance={'25%'}>
                    <h1>
                      <span>
                        Where media <br /> meets ecommerce
                      </span>
                    </h1>
                  </Fade>

                  <Fade bottom duration={1000} delay={250} distance={'25%'}>
                    <h2>And everyone wins</h2>
                  </Fade>

                  <Fade bottom duration={1000} delay={375} distance={'25%'}>
                    <Link to="/contact" className="button cp-primary">
                      Discover Knocking
                    </Link>
                  </Fade>
                </div>
              </div>

              <div className="columns is-mobile">
                <div className="column is-11-mobile is-10-tablet is-10-desktop is-10-widescreen is-8-fullhd cp-slider-wrapper">
                  <Slider
                    className="cp-slider"
                    {...settings}
                    ref={slider => (this.slider = slider)}
                  >
                    {this.state.slides.map((imageItem, i) => (
                      <img
                        src={imageItem.image}
                        alt={imageItem.alt}
                        key={`slide-${i}`}
                      />
                    ))}
                  </Slider>
                </div>
              </div>

              <div className="cp-pulse"></div>
            </div>
          </div>
        }
      >
        <SEO
          title="Knocking | Where Media Meets Ecommerce"
          titleAsIs={true}
          keywords={[
            'Knocking',
            'Ecommerce solution',
            'Brand deals',
            'Local steals and deals',
            'Gangas and deals',
          ]}
          description="New revenue streams for networks. Exponential brand awareness. Exclusive brand deals for consumers. Join the revolution!"
        />

        <div className="page-content pages-index pages-about">
          <div className="section-desc">
            <div className="container">
              <div className="columns is-vcentered">
                <div className="column is-1 cp-wide"></div>

                <div className="column is-4">
                  <h3>
                    A new era
                    <br />
                    for media
                  </h3>
                </div>

                <div className="column is-1"></div>

                <div className="column is-6">
                  <p className="cp-text">
                    We enable our media partners to access new revenue streams
                    through shoppable content that champions transparency,
                    integrity and honesty.
                  </p>
                </div>
              </div>

              <div
                className="columns is-vcentered is-multiline is-mobile cp-logos"
                style={{ justifyContent: 'center' }}
              >
                {MEDIA_PARTNERS.slice(0, 8).map((image, i) => (
                  <div
                    className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                    key={`mp-${i}`}
                  >
                    <Fade
                      effect="fadeInUp"
                      effectOut="fadeOutLeft"
                      delay={62.5 * i}
                    >
                      <img src={image} />
                    </Fade>
                  </div>
                ))}
              </div>

              <div
                className="columns is-vcentered is-multiline is-mobile cp-logos-7"
                style={{ justifyContent: 'center' }}
              >
                {MEDIA_PARTNERS.slice(8).map((image, i) => (
                  <div
                    className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                    key={`mp-${i}`}
                  >
                    <Fade
                      effect="fadeInUp"
                      effectOut="fadeOutLeft"
                      delay={62.5 * i}
                    >
                      <img src={image} />
                    </Fade>
                  </div>
                ))}
              </div>

              <div className="columns is-vcentered is-multiline is-mobile cp-logos-thin">
                {MEDIA_PARTNERS.map((image, i) => (
                  <div
                    className="column is-6-mobile is-4-tablet is-narrow-desktop is-narrow-widescreen is-narrow-fullhd"
                    key={`mp-${i}`}
                  >
                    <Fade
                      effect="fadeInUp"
                      effectOut="fadeOutLeft"
                      delay={62.5 * i}
                    >
                      <img src={image} />
                    </Fade>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="section-exp">
            <div className="cp-pulse-top-bg"></div>
            <div className="cp-mobile-top-bg"></div>

            <div className="cp-first">
              <div
                className="cp-img"
                aria-label="Founder of Beauty Flat Cosmetics Ron Robinson stands in a lab coat"
              />

              <div className="container">
                <div className="columns is-vcentered">
                  <div className="column is-6 cp-photo">
                    <img src={BRAND} />
                  </div>

                  <div className="column is-1"></div>

                  <div className="column is-5">
                    <Fade bottom duration={1000} delay={125} distance={'25%'}>
                      <h3>A big deal for brands</h3>
                    </Fade>

                    <Fade top duration={1500} delay={125} distance={'30%'}>
                      <p>
                        We help brands tell their story on platforms people
                        trust, and grow their reach exponentially through
                        world-class content and ecommerce environments.
                      </p>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>

            <div className="container cp-divider-wrapper">
              <div className="cp-divider cp-mobile">
                <div className="cp-first-divider"></div>
                <div className="cp-second-divider"></div>
              </div>
            </div>

            <div className="cp-second">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={'25%'}>
                  <h3>More value for consumers</h3>
                </Fade>

                <Fade bottom duration={1300} delay={250} distance={'50%'}>
                  <p>
                    We create content that serves the interests of the viewer
                    above all, with products and promotions that bring real,
                    tangible value to people’s lives.
                  </p>
                </Fade>

                <img
                  src={PEOPLE}
                  alt="Delivery man drops off a package to happy, smiling family"
                />
              </div>

              <div className="cp-divider">
                <div className="cp-first-divider"></div>
                <div className="cp-second-divider"></div>
              </div>
            </div>

            <div className="page-content pages-shows">
              <div className="section-shows">
                <div className="container">
                  <Fade bottom duration={1000} delay={125} distance={'25%'}>
                    <h3 className="cp-title">Our shows</h3>
                  </Fade>

                  <div className="columns is-multiline">
                    {allShows
                      .filter(({ node }) => node.frontmatter.hidden !== true)
                      .slice(0, 6)
                      .map(({ node }, i) => {
                        const hasEpisodes =
                          node.frontmatter.episodes &&
                          node.frontmatter.episodes.length !== 0

                        const Post = (
                          <>
                            <div className="cp-img">
                              <img src={node.frontmatter.thumbnail} />

                              <video
                                width="100%"
                                height="100%"
                                controls={false}
                                loop
                                autoPlay
                              >
                                <source
                                  src={node.frontmatter.video}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>

                            <div className="cp-content">
                              <h3>{node.frontmatter.title}</h3>

                              <p className="cp-description">
                                {node.frontmatter.description}
                              </p>

                              <i>
                                {hasEpisodes ? (
                                  <>
                                    See all segments <FiArrowRight />
                                  </>
                                ) : (
                                  <>
                                    See more <FiArrowUpRight />
                                  </>
                                )}
                              </i>
                            </div>
                          </>
                        )

                        return (
                          <Fade
                            key={node.fields.slug}
                            duration={600}
                            top
                            distance={`${(i % 3) * 5}%`}
                          >
                            <div className="column is-one-third">
                              <div className="show-item">
                                {hasEpisodes && (
                                  <Link to={node.fields.slug}>{Post}</Link>
                                )}

                                {!hasEpisodes && (
                                  <a
                                    href={node.frontmatter.link}
                                    target="_blank"
                                  >
                                    {Post}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Fade>
                        )
                      })}
                  </div>

                  <div className="cp-all-link">
                    <Fade bottom duration={1000} delay={375} distance={'25%'}>
                      <Link to="/shows" className="button cp-primary">
                        View all shows
                      </Link>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>

            <div className="page-content pages-shows">
              <div className="section-shows">
                <div className="container">
                  <Fade bottom duration={1000} delay={125} distance={'25%'}>
                    <h3 className="cp-title">Our impact</h3>
                  </Fade>
                </div>
              </div>
            </div>

            <div className="page-content pages-sf">
              <div className="container">
                <Slider
                  className="cp-slider"
                  {...testimonialsSliderSettings}
                  ref={r => (testimonialsSlider = r)}
                >
                  {(PAGE_CONTENTS.testimonials || []).map((slide, i) => (
                    <div key={`slide-${i}`}>
                      <p className="cp-text">{slide.text}</p>

                      <div className="cp-prs">
                        <img src={slide.image} />

                        <p className="cp-name">{slide.name}</p>
                        <p className="cp-company">{slide.company}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>

            <div className="cp-third">
              <div className="container">
                <h3>
                  Knocking is changing everything for media, brands and
                  consumers
                </h3>

                <Fade bottom duration={1000} delay={125} distance={'50%'}>
                  <Link to="/contact" className="button">
                    Get started
                  </Link>
                </Fade>
              </div>
            </div>

            <div className="cp-pulse-bottom"></div>
            <div className="cp-pulse-bottom-bg"></div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { posttype: { eq: "shows" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            description
            hidden
            episodes {
              date(formatString: "MMMM DD, YYYY")
              title
              vimeo_id
              image
            }
            link
            title
            thumbnail
            video
          }
        }
      }
    }
  }
`
